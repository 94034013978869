<template>
  <!--Navbar Start-->
  <nav class="navbar navbar-expand-lg fixed-top " id="navbar"
    :class="{ 'navbar-light': navcolor === 'light', 'navbar-light bg-white': navcolor === 'light-white' }">
    <div class="container">
      <!-- LOGO -->
      <router-link class="navbar-brand logo" to="/" >
        <img  @click="scrollToTop" src="@/assets/logo.png" alt="" class="logo-dark" height="28" />
        <img  @click="scrollToTop" src="@/assets/full_logo.png" alt="" class="logo-light" height="30" />
      </router-link>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse"
        aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" @click="toggleMenu()">
        <menu-icon></menu-icon>
      </button>
      <div class="collapse navbar-collapse" id="navbarCollapse">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item" @click="scrollToTop">
            <router-link to="/" class="nav-link" :class="{ active: isActive('/') }">
              Home
            </router-link>
          </li>
          <li class="nav-item" @click="scrollToTop">
            <router-link to="/distribution" class="nav-link" :class="{ active: isActive('/distribution') }">
              Distribution
            </router-link>
          </li>
          <li class="nav-item" @click="scrollToTop">
            <router-link to="/services" class="nav-link" :class="{ active: isActive('/services') }">
              Services
            </router-link>
          </li>
          <li class="nav-item" @click="scrollToTop">
            <router-link to="/pricing" class="nav-link" :class="{ active: isActive('/pricing') }">
              Pricing
            </router-link>
          </li>
          <li class="nav-item" @click="scrollToTop">
            <router-link to="/promotion" class="nav-link" :class="{ active: isActive('/promotion') }">
              Promotion
            </router-link>
          </li>

        </ul>
        <router-link to="/sign-up">
          <button class="btn btn-sm rounded-pill nav-btn ms-lg-3">Sign Up</button>
        </router-link>
      </div>
    </div>
    <!-- end container -->
  </nav>
  <!-- Navbar End -->
</template>

<script>
import { MenuIcon } from "vue-feather-icons";

export default {
  props: {
    navcolor: {
      type: String,
      default: null,
    },
  },
  components: { MenuIcon },
  mounted() {
    const navbar = document.getElementById("navbar");

    window.onscroll = () => {
      if (window.scrollY > 50) {
        navbar.classList.add("nav-sticky");
      } else {
        navbar.classList.remove("nav-sticky");
      }
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    toggleMenu() {
      document.getElementById("navbarCollapse").classList.toggle("show");
    },
    isActive(path) {
      return this.$route.path === path;
    },
  },
};
</script>

<style scoped>
#navbar.nav-sticky {
  background-color: rgba(255, 255, 255, 0.9);
  /* Slightly opaque */
  backdrop-filter: blur(15px);
  /* Apply blur effect */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  /* Add shadow */
  transition: all 0.3s ease;
  /* Smooth transition */
}

/* Custom styles for active and inactive links */
.navbar-nav .nav-link {
  color: #23272a;
  /* Default link color */
  transition: color 0.3s ease;
}

.navbar-nav .nav-link:hover {
  color: #007bff;
  /* Hover color */
}

.navbar-nav .nav-link.active {
  color: #0d6efd;
  /* Bootstrap primary color for active link */
  font-weight: bold;
  /* Optional: Bold active link */
}

.navbar {
  background-color: transparent;
  /* Semi-transparent background */
  backdrop-filter: blur(10px);
  /* Blur effect */
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  /* Optional shadow for depth */
  transition: background-color 0.3s ease, backdrop-filter 0.3s ease;
  /* Smooth transition */
}

.nav-sticky {
  background-color: transparent;
  /* Slightly opaque when scrolled */
  backdrop-filter: blur(15px);
  /* Increase blur on scroll */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  /* Stronger shadow when sticky */
}
</style>