<template>
    <div class="scrollable-list" data-aos="zoom-out-up">
        <div class="list-wrapper d-flex align-items-center">
            <div v-for="(item, index) in items" :key="index" class="item-card">
                <img :src="item.logo" :alt="item.name" class="logo-image" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
    },
    data() {
        return {
            items: [
                { "name": "Spotify", "logo": "https://www.logo.wine/a/logo/Spotify/Spotify-Black-Logo.wine.svg" },
                { "name": "Apple Music", "logo": "https://www.logo.wine/a/logo/Apple_Music/Apple_Music-Logo.wine.svg" },
                { "name": "Amazon Music", "logo": "https://www.logo.wine/a/logo/Amazon_(company)/Amazon_(company)-Logo.wine.svg" },
                { "name": "YouTube Music", "logo": "https://www.logo.wine/a/logo/YouTube_Music/YouTube_Music-Logo.wine.svg" },
                { "name": "Facebook", "logo": "https://www.logo.wine/a/logo/Facebook/Facebook-Logo.wine.svg" },
                { "name": "TikTok", "logo": "https://www.logo.wine/a/logo/TikTok/TikTok-Logomark%26Wordmark-Logo.wine.svg" },
                { "name": "Tidal", "logo": "https://www.logo.wine/a/logo/Tidal_(service)/Tidal_(service)-Logo.wine.svg" },
                { "name": "SoundCloud", "logo": "https://www.logo.wine/a/logo/SoundCloud/SoundCloud-Logo.wine.svg" },
                { "name": "Deezer", "logo": "https://www.logo.wine/a/logo/Deezer/Deezer-Logo.wine.svg" },
            ]


        };
    },
};
</script>

<style scoped>
/* Container for auto-scrolling and hiding scrollbar */
.scrollable-list {
    margin-top: 20%;
    overflow-x: auto;
    /* Enables horizontal scrolling */
    white-space: nowrap;
    position: relative;

    /* Adjust based on card height */
    padding: 10px 0;
    scrollbar-width: none;
    /* Firefox hides scrollbar */
    -ms-overflow-style: none;
    /* IE and Edge hides scrollbar */
}

.scrollable-list::-webkit-scrollbar {
    display: none;
    /* Chrome and Safari hides scrollbar */
}

/* Wrapper for cards with animation */
.list-wrapper {
    display: flex;
    gap: 20px;
    animation: scroll-left 10s linear infinite;
    /* Auto-scroll animation */
}


/* Individual card styling */
.item-card {
    flex: 0 0 auto;
    /* Prevent shrinking */
    width: 250px;
    height: 90px;
    background: transparent;
    /* Slight transparency */
    backdrop-filter: blur(15px);
    /* Apply blur effect */
    box-shadow: 0 4px 15px rgba(63, 63, 63, 0.2);
    /* Smooth shadow for better aesthetics */

    border: 1px solid #ffffff;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    animation: border-color-change 3s infinite linear;
    /* Border animation */
}

/* Image inside the card */
.logo-image {
    max-width: 180px;
    max-height: 90px;
    object-fit: contain;
}

@media (max-width: 768px) {
    .item-card {
        width: 150px;
        height: 60px;
    }

    .logo-image {
        max-width: 100px;
        max-height: 60px;
        object-fit: contain;
    }

}

/* Keyframes for border animation */
@keyframes border-color-change {
    0% {
        border-color: rgb(0, 0, 0);
        /* Red */
    }

    25% {
        border-color: rgb(255, 255, 255);
        /* Yellow */
    }

    50% {
        border-color: rgb(0, 0, 0);
        /* Green */
    }

    75% {
        border-color: rgb(255, 255, 255);
        /* Blue */
    }

    100% {
        border-color: rgb(0, 0, 0);
        /* Back to Red */
    }
}

/* Keyframes for auto-scroll */
@keyframes scroll-left {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}
</style>
